import SelectLanguage from '@/components/SelectLanguage/SelectLanguage';
<template>
  <div class="notAllowed">
    <template v-if="status === 1">
      <div class="content-forbidden">
        <h2>{{ $t('common.oh') }}</h2>
        <p>{{ $t('forbidden.not-allowed') }}</p>
      </div>
      <h1 class="title">{{ $t(`pass.title`) }}</h1>
      <div class="notAllowed-boxes">
        <div class="box" v-for="(item, index) in plans" :key="index">
          <form
            :ref="item.code"
            action="https://sis-t.redsys.es:25443/sis/realizarPago"
            method="post"
            :id="item.code"
            :name="item.code"
          >
            <input
              type="text"
              name="Ds_SignatureVersion"
              v-model="Ds_SignatureVersion"
              hidden
            />
            <input
              type="text"
              name="Ds_MerchantParameters"
              v-model="Ds_MerchantParameters"
              hidden
            />
            <input
              type="text"
              name="Ds_Signature"
              v-model="Ds_Signature"
              hidden
            />
            <div>
              <h3 class="box-title">{{ $t(`${item.name}`) }}</h3>
              <!-- <ul>{{}}
                <li v-for="(description, index) in item.description" :key="index">
                  {{ description }}
                </li>
              </ul> -->
            </div>
            <h4 class="price">
              {{ item.price }}€
              <span class="payment">{{ item.payment }}</span>
            </h4>
            <button class="button" @click.prevent="checkPayment(item)">
              {{ $t('forbidden.get-pass') }}
            </button>
          </form>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="content-forbidden">
        <h2>{{ $t('common.oh') }}</h2>
        <p>{{ $t('forbidden.register-needed') }}</p>
        <RouterLink to="/login" class="button">
          {{ $t('forbidden.register') }}
        </RouterLink>
      </div>
    </template>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import api from '@/services/api.service.js'

export default {
  name: 'NotAllowed',
  data() {
    return {
      amount: null,
      paymentData: {
        DS_MERCHANT_AMOUNT: '2',
        DS_MERCHANT_CURRENCY: '978',
        DS_MERCHANT_MERCHANTCODE: '355105636',
        DS_MERCHANT_ORDER: '',
        DS_MERCHANT_TERMINAL: '1',
        DS_MERCHANT_TRANSACTIONTYPE: '0',
        DS_MERCHANT_URLOK: `/purchased`,
        DS_MERCHANT_URLKO: `/notAllowed?tpv=ko`
      },
      Ds_MerchantParameters: null,
      Ds_Signature: null,
      Ds_SignatureVersion: null,
      plans: [],
      prices: [
        {
          ref: 'pass1month',
          title: 'pass.1month',
          //description: ['Pago único', 'Acceso ilimitado', 'Duración 48hs'],
          price: '2,99€',
          amount: '2.99'
        },
        {
          ref: 'pass6month',
          title: 'pass.6month',
          //description: ['Pago mensual', 'Acceso ilimitado'],
          price: '12€',
          amount: '12.00'
        },
        {
          ref: 'pass12month',
          title: 'pass.12month',
          //description: ['Pago anual', 'Acceso ilimitado'],
          price: '24€',
          amount: '24.00'
          //payment: '/año',
        }
      ]
    }
  },
  mounted() {
    this.getPlans()
  },
  computed: {
    ...mapState(['status'])
  },
  methods: {
    async checkPayment(product) {
      await this.createOrder(product)
      this.amount = product.price
      let encodeData = await this.encodePayment()
      let f = document.getElementById(product.code)
      if (encodeData) f.submit()
    },
    async getPlans() {
      const res = await api.get.plans()
      console.log(res)
      this.plans = res.data.plans
    },
    async createOrder(data) {
      console.log('La data es')
      console.log(data)
      const res = await api.post.newOrder({
        product: data.code,
        price: data.price
      })
      this.paymentData.DS_MERCHANT_ORDER = res.data.order.toString()
    },
    async encodePayment() {
      try {
        this.priceForRedsys()
        let merchant = this.$CryptoJS.enc.Utf8.parse(
          JSON.stringify(this.paymentData)
        )
        this.Ds_MerchantParameters = merchant.toString(
          this.$CryptoJS.enc.Base64
        )
        let key = this.$CryptoJS.enc.Base64.parse(
          'sq7HjrUOBfKmC576ILgskD5srU870gJ7'
        )
        let iv = this.$CryptoJS.enc.Hex.parse('0000000000000000')
        let cipher = this.$CryptoJS.TripleDES.encrypt(
          this.paymentData.DS_MERCHANT_ORDER,
          key,
          {
            iv: iv,
            mode: this.$CryptoJS.mode.CBC,
            padding: this.$CryptoJS.pad.ZeroPadding
          }
        )
        let signature = this.$CryptoJS.HmacSHA256(
          this.Ds_MerchantParameters,
          cipher.ciphertext
        )
        this.Ds_Signature = signature.toString(this.$CryptoJS.enc.Base64)
        this.Ds_SignatureVersion = 'HMAC_SHA256_V1'
        return true
      } catch (error) {
        console.error(error)
        return false
      }
    },
    priceForRedsys() {
      let priceN = parseFloat(this.amount)
      if (Number.isInteger(priceN)) {
        this.paymentData.DS_MERCHANT_AMOUNT = priceN.toString() + '00'
      } else {
        let price_fixed = priceN.toFixed(2).toString()
        let price_string = price_fixed.replace('.', '')
        this.paymentData.DS_MERCHANT_AMOUNT = price_string
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.notAllowed {
  max-width: 1200px;
  margin: auto;
  .title {
    font-size: 35px;
  }
  &-boxes {
    @include flex();
    justify-content: space-evenly;
    height: 60%;
    .box {
      border: 1px solid white;
      text-align: center;
      width: 250px;
      @include flex();
      flex-direction: column;
      justify-content: space-around;
      padding: 20px;
      transition: all 0.2s ease-in-out;
      background: #000;
      &-title {
        font-size: 25px;
        text-transform: uppercase;
        padding-bottom: 15px;
        border-bottom: 1px solid white;
      }
      ul {
        text-align: left;
        li {
          color: white;
        }
        height: 70px;
      }
      .price {
        font-size: 55px;
        margin: 0;
      }

      &:hover {
        background-color: white;
      }
      &:hover .payment {
        color: black;
      }
      &:hover .box-title {
        color: black;
        border-bottom: 1px solid black;
      }
      &:hover ul li {
        color: black;
      }
      &:hover h4 {
        color: black;
      }
    }
  }
  .button-container {
    text-align: center;
  }
  .button {
    margin: 10px;
    color: white;
    background-color: #61b298;
    border: none;
    padding: 5px 15px;
    font-size: 20px;
    text-align: center;
    cursor: pointer;
  }
  .payment {
    font-size: 12px;
    color: white;
  }
  .content-forbidden {
    width: 100%;
    @include flex(center, false, true);
    padding: 30px 40px;
    border: 1px solid white;
    margin-bottom: 20px;
    margin-bottom: 30px;
    h2 {
      font-size: 34px;
    }
    p {
      color: white;
      font-size: 20px;
      text-align: center;
      margin-bottom: 30px;
    }
    button {
      font-size: 18px;
    }
  }
}

@media screen and (max-width: 800px) {
  .notAllowed {
    &-boxes {
      .box {
        height: 260px;
        width: 200px;
        &-title {
          font-size: 20px;
        }
      }
    }
  }
}

@media screen and (max-width: 700px) {
  .notAllowed {
    height: auto;
    text-align: center;
    .title {
      margin-bottom: 40px;
      font-size: 35px;
    }
    &-boxes {
      flex-direction: column;
      .box {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
